.barraSuperior, .barraSuperior.affix {
  background: var(--colorDefault);
  color: var(--colorText);
  z-index: 999;
  padding: 15px;
  box-shadow: 0px 5px 5px -2px #e0e0e0;
}
.barraSuperior .logo {
  /*padding-left: 20px;*/
  color: var(--colorText);
}
.barraSuperior .imgMenu #btnMenu {
  cursor: pointer;
  color: var(--colorText2);
}
#menuUser {
  background: var(--backgroundDefault);
  color: var(--colorText);
  position: fixed;
  right: 0;
  top: 60px;
  height: 100%;
  z-index: 999;
  width: auto;
  padding: 0px;
  visibility: hidden;
  transform: translateX(250px);
  transition: all 0.3s ease;
  box-shadow: 0px 10px 10px -2px var(--colorText);
}
#menuUser.active {
  visibility: visible;
  transform: translateX(0px);
}
#menuUser li{
  padding: 15px 40px 15px 25px;
  list-style: none;
  width: 100%;
  cursor: pointer;
}
#menuUser li.active, #menuUser li:hover{
  background: var(--colorDefault);
  color: #000;
}
#menuUser li span,  #menuUser li svg{
  margin-right: 15px;
}
#menuUser li img {
  margin-top: -5px;
}
#logo-barra{
  width: 95px;
  height: 100%;
}
#titleMenu {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  color: var(--colorText2);
}
.bodyDashboard {
  margin: 0px auto 0;
  padding-top: 15px;
  max-width: 750px;
  width: 100%;
  height: 100%;
  max-height: 92vh;
  overflow: scroll;
  overflow-x: hidden;
}
.itensHome {
  width: 100%;
  height: 80vh;
}
.itensHome .title{
  margin-top: 30px;
  margin-left: 15px;
}
.itemHome {
  padding: 20px 15px;
  margin: 5px;
  box-shadow: 0px 0px 8px 0px rgb(152 152 152);
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 15px;
}
.itemHome span{
  color: var(--colorText2);
}
.itemHome .itemHomeIcon{
  color: var(--colorText);
}
.itemHome .title{
  margin-top: 20px;
  color: var(--colorText);
  font-weight: bold;
  font-size: 25px;
  margin-left: 0;
}
.dashboardList .itemDashboardList, .musicList .itemMusicList {
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 15px;
  background: rgb(253, 253, 253);
  /*border: 2px solid rgb(245, 245, 245);*/
  box-shadow: 0px 2px 8px -2px rgb(177, 177, 177);
  list-style: none;
  border-left: 3px solid var(--colorDefault);
}
.userList .itemUserList {
  margin-bottom: 0px;
  margin-top: 8px;
  border-radius: 5px;
  padding: 10px 10px;
  background: rgb(253, 253, 253);
  box-shadow: 0px 2px 5px -2px rgb(177, 177, 177);
  list-style: none;
  border-left: 3px solid var(--colorDefault);
}
.dashboardList .itemDashboardListActive{
  box-shadow: 0px 0px 10px 1px #636363;
  /*paddingbox-shadow: 0px 0px 2px 3px var(--colorDefault);
  border: 0;*/
}
.headList {
  cursor: pointer;
}
.dashboardList .itemDashboardList .iconItem, .musicList .itemMusicList .iconItem{
  margin: 5px;
  color: dimgrey;
}
.dashboardList .itemDashboardList .edit{
  color: #01d401;
}
.dashboardList .itemDashboardList .delete{
  color: #888888;
}
.dashboardList .itemDashboardList .itemImg {
  width: 100%;
  max-width: 160px;
}
.dashboardList .itemDashboardList label#thumbnail{
  display: flex;
  width:100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ddd;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.dashboardList .itemDashboardList label#background{
  width:100%;
  height: 350px;
  margin-bottom: 1rem;
  border: 1px dashed #ddd;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.dashboardList .itemDashboardList .itemDashboardListSwitch {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  margin-top: -10px;
  z-index: 10;
}
.linkListItem {
  text-decoration: underline;
  font-weight: bold;
  margin-top: -14px;
  position: absolute;
  right: 0;
  margin-right: 35px;
  color: var(--colorDefault);
}
/*.musicList .itemMusicList label#thumbnail {
  width: 100%;
  height: 250px;
  margin-bottom: 1rem;
  border: 1px dashed #ddd;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}*/
audio {
  margin-left: 15px;
  margin-right: 15px;
  height: 40px;
}
#audio-upload[type="file"] {
  display: none;
}
label#thumbnail input, label#background input, label#imageShare input {
  display: none;
}
label#thumbnail.has-thumbnail, label#background.has-thumbnail, label#imageShare.has-thumbnail {
  border: 0 !important;
}
label#thumbnail img, label#background img, label#imageShare img {
  /*top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -100%);*/
}
label#thumbnail.has-thumbnail img, label#background.has-thumbnail img, label#imageShare.has-thumbnail img{
  display: none;
}

.title {
  color: var(--colorText);
  font-weight: 700;
  font-size: 20px;
  width: 100%;
}
.labelFilter {
  color: var(--colorText);
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;
  margin-right: 8px;
}
.iconFilter {
  color: var(--colorText);
}
.btnNew {
  margin: 5px 0px;
  border-radius: 50%;
  border: none;
  padding: 5px;
  width: 50px;
  height: 50px;
  background: var(--colorDefault);
  color: #000;
  box-shadow: 0px 2px 10px -2px #bfbfbf;
}
.btnDeleteImg {
  position: absolute;
  margin-top: 92px;
  margin-left: 27px;
  border-radius: 50%;
  border: none;
  padding: 5px;
  width: 30px;
  height: 30px;
  background: #f8f8f8;
  color: var(--colorDefault);
  box-shadow: 0px 2px 10px -2px #bfbfbf;
}
.iconInputRight{
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 25px;
  margin-top: 13px;
  color: #ababab;
}
.contentEditor {
  border: 1px solid #ced4da;
  border-radius: .25rem;
}
.contentEditor .inputEditor {
  padding: 15px;
}
.imagesPreview {
  display: inline-block;
  width: 130px;
  height: 130px;
  margin: 10px;
  border-radius: 10px;
  background-size: cover !important;
  background-position: center !important;
  cursor: pointer;
}
#labelImages {
  cursor: pointer;
}
#formNotification{
  width: 100%;
}
.idYoutubeSelected {
  margin-bottom: 5px;
  margin-left: 7px;
  padding: 4px;
  background-color: #ddd;
  border-radius: 5px;
  /*width: 80px;*/
}
.idYoutubeSelected .btnDeleteIdYoutube {
  margin-left: 8px;
  cursor: pointer;
}
.categoryListBody {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}
.categoryItemListBody {
  height: 120px;
  width: 130px;
  border-radius: 15px;
  border: 2px dashed #A5A5A5;
  padding: 10px;
  justify-content: center;
  text-align: center;
  margin: 10px;
  cursor: pointer;
}  
.categoryItemListBodyActive {
  background: var(--colorDefault);
  border-color: var(--colorDefault);
  border-style: solid;
}
.categoryItemTextListBody {
  font-size: 14px;
  color: #a0a0a0;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;
  line-height: 1.3em;
}
.categoryItemTextListBodyActive {
  color: #fff;
  font-weight: bold;
}
.categoryItemIconistBody {
  color: #a0a0a0;
  margin-top: 10px;
}
.categoryItemIconistBodyActive {
  color: #fff;
  margin-top: 10px;
}

/* ORDERS */ 
.titleDate {
  width: auto;
  cursor: pointer;
  background: var(--backgroundDefault);
  border: 0;
  color: var(--colorText);
  font-size: 20px;
  font-weight: bold;
}
.iconDate {
  /*position: absolute; 
    top: 0;
    right: 0; */
    margin-left: 15px;
    margin-right: 8px;
    margin-top: 8px;
    /* cursor: pointer; */
    color: var(--colorText);
}
.iconRefresh {
  position: absolute;
  top: 0;
  right: 0;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  cursor: pointer;
  color: var(--colorText);
}

.imgItemOrder {
  height: 90px;
  width: 90px;
  border-radius: 8px;
  background-size: cover;
  background-position: 50%;
}
.linkDownload {
  text-decoration: underline;
  font-weight: bold;
  color: #0595a1;
}
 
/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  /*#logo-barra{
    width: 60px;
  }*/
  .dashboardList .itemDashboardList label#thumbnail{
    width:100%;
    height: 160px;
  }
  .itensHome {
    height: auto;
  }
}